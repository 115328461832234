import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { base_URL } from '../middleware/config';
import '../css/loginSignup.css';
import '../css/commonControl.css';
import CallSpinner from '../components/callSpinner';

const Login = () =>{
    const auth = localStorage.getItem('key');
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(()=>{
        if(auth) navigate('/');
    })

    const navigate = useNavigate();

    const handleFormSubmit = async () => {
        setIsLoading(true);
        let response = await axios.post(`${base_URL}/login`, formData);
        if(response.data.status === 200){
            alert(`${response.data.message}`);
            // toast.success(`${response.data.message}`,{
            //     theme: "colored",
            //     autoClose: 10000,
            //     position: "top-left"
            // });
            localStorage.setItem("key",JSON.stringify(response.data.body));
            setIsLoading(false);
            navigate('/');
            }
        else {
            setIsLoading(false);
            alert(`${response.data.message}`);            
            // toast.error(`${response.data.message}`);
            return;
        }
    }

    const showPass = () => {
        setIsChecked(!isChecked);
    }

    return(
        <div className='login'>            
            <table className='accessTable'>
                <tr>
                    <td>
                    <h3>Admin Login</h3>
                        <small className = "inputLabels">User Email:-</small>
                        <input className='text' type="text" placeholder="Enter User Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} id="email"></input>
                    </td>
                </tr>
                <tr>
                    <td>
                        <small className = "inputLabels">Password:-</small>
                        <input className='text' type= {isChecked? "text" : "password"} placeholder="Enter Password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} id="password"></input>                        
                    </td>
                </tr>
                <tr className='passChkbox'>
                    <input className='text' onChange = {showPass} type="checkbox"  id="checkbox"></input>    
                    <small>Show Password</small>                    
                </tr>
                <tr>
                    <td>
                        <button onClick={handleFormSubmit} className="btn-gbl-green btn " type="button">Login</button>
                    </td>
                </tr>
            </table>
            { isLoading? <CallSpinner/>:''}
            {/* <div className='inputBox'>
                <input className='input_text' type="text" placeholder="Enter email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} id="email"></input>
                <input className='input_text' type="Password" placeholder="Enter password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} id="password"></input>
            </div>
            <button onClick={handleFormSubmit} className="appButton" type="button">Login</button> */}
            {/* <ToastContainer/> */}
        </div>
    )
}

export default Login;