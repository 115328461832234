import React from 'react'
import LoadingSpinner from '../components/LoadingSpinner';
import '../css/callSpinner.css'

const CallModelSpinner = () => {
  return (
    <div className='modelSpinner'>
    <LoadingSpinner />
</div>
  )
}

export default CallModelSpinner;