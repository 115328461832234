import React from 'react';
import '../css/home.css';
import wallPaper from '../images/TRMC.jpeg';

const Home = () => {
  return (
    <div className='home'>
      <img className="homeImage" src={wallPaper} alt="img file"/>
    </div>
  )
}

export default Home;
