import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_URL } from "../middleware/config";
import Modal from "../components/Modal";
import CallModelSpinner from "../components/callModelSpinner";
import { useNavigate } from "react-router-dom";
import "../css/setOfficials.css";

const SetOfficals = () => {
    const auth = localStorage.getItem("key");
    const [officalsData , setOfficalsData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [token, setToken] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editModel, setEditModel] = useState(false);    
    
    const getAuthorityMembers = async () => {
        await axios.get(`${base_URL}/staffsStricture/`).then((getData) => {
            setOfficalsData(getData.data.body);
        });
    };

    let navigate = useNavigate();

    const close = () => {
      setEditModel(false);
    };
 
  useEffect(() => {
    getAuthorityMembers();
    setToken(JSON.parse(auth).token);
    if (!auth) navigate("/");
  }, []);

    //function to edit
    const editFileChange = async () => {
        setIsLoading(true);
        let data = officalsData[0];
        setEditData(data);
        setEditModel(true);
        setIsLoading(false);
    };

    const handleFormSubmit = async () => {
        setIsLoading(true);
        let finalData = { ...editData, token: token };
        let response = await axios.put(`${base_URL}/staffsStricture/`, finalData);
        if (response.data.status === 200) {
          alert(`${response.data.message}`);
          navigate("/officals");
          setIsLoading(false);
          window.location.reload(false);
        } else {
          alert(`${response.data.message}`);
          setIsLoading(false);
          return;
        }
      };

  return (
    <div className='setOfficals'>
        <h2 className="title">Set Officals Key ID's</h2>
        <table className="contactForm" id="contactData">
        <tr>
          <td>
            <small>In case to change Officials Key. Press Edit.</small>
          </td>
        </tr>
      </table>
      <div className="tblWrapper wrapperHeightContact">
        <table className="dataTable">
          <thead>
            <tr>
              <th> Mgmt-Committee Ids </th>
              <th> Exe-CommitteeIds </th>
              <th> Campus-Authorities Ids </th>
              <th> Teachers Associations Ids </th>
              <th> Employee Associations Ids </th>
            </tr>
          </thead>
          <tbody>
            {officalsData &&
              officalsData.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{data.mgmtCommitteeIds} </td>
                    <td>{data.exeCommitteeIds} </td>
                    <td>{data.campusAuthoritiesIds} </td>
                    <td>{data.teachersAssociationsIds}</td>
                    <td>{data.empAssociationsIds}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <table className="dataTable">
          <tr>
            <div className="btnFlex">
              <button variant="contained" onClick={() => editFileChange()} className="btn-gbl-blue btn contactEditBtn" type="button">
                Officals Key ID's
              </button>
            </div>
          </tr>
        </table>
    </div>
    <Modal
        width="fit-content"
        border="none"
        modal={editModel}
        title="Place the employee id's of the officials in series as need to display."
        close={close}
        content={
          <form onSubmit={handleFormSubmit}>
            <table className="officalsTable">
              <tr>
                <td>
                {console.log(editData,'editData')}
                  <small className="inputLabels">Mgmt-Committee Ids:-</small>
                  <input className="text textLong" type="text" placeholder="Mgmt-Committee Ids" required value={editData.mgmtCommitteeIds} onChange={(e) => setEditData({ ...editData, mgmtCommitteeIds: e.target.value })} />

                  <small className="inputLabels">Exe-Committee Ids:-</small>
                  <input className="text textLong" type="text" placeholder="Exe-Committee Ids" required value={editData.exeCommitteeIds} onChange={(e) => setEditData({ ...editData, exeCommitteeIds: e.target.value })} />

                  <small className="inputLabels">Campus-Authorities Ids:-</small>
                  <input className="text textLong" type="text" placeholder="Campus-Authorities Ids" required value={editData.campusAuthoritiesIds} onChange={(e) => setEditData({ ...editData, campusAuthoritiesIds: e.target.value })} />

                  <small className="inputLabels">Teachers Associations Ids:-</small>
                  <input className="text textLong" type="text" placeholder="Teachers Associations Ids" required value={editData.teachersAssociationsIds} onChange={(e) => setEditData({ ...editData, teachersAssociationsIds: e.target.value })} />

                  <small className="inputLabels">Employee Associations Ids:-</small>
                  <input className="text textLong" type="text" placeholder="Employee Associations Ids" required value={editData.empAssociationsIds} onChange={(e) => setEditData({ ...editData, empAssociationsIds: e.target.value })} />
                </td>               
              </tr>
            </table>           
            {isLoading ? <CallModelSpinner /> : ""}
          </form>
        }
        actions={
          <button className="btn-gbl-green btn contact-form-btn" type="submit" onClick={handleFormSubmit}> Submit </button>
        }
      />
    </div>
  )
}

export default SetOfficals