import axios from "axios";
import { base_URL } from "../middleware/config";
// import { MenuItem } from '@mui/material';

// const unitData = [
//     {text: "Pices" , value : 1},
//     {text: "Box" , value : 2},
//     {text: "Kg" , value : 3},
//     {text: "Liters" , value : 4},
// ];

// const unitList = [];
// for(let i=0;i<unitData.length;i++){
//     unitList.push(<option key={i} value={unitData[i].value}>{unitData[i].text}</option>)
// }

const roleData = async () =>{
    let response =  await axios.get(`${base_URL}/roles`);
    return response.data.body;
  }

const rolesList = (list) => {
    let lists = [];
    for (let i = 0; i < list.length; i++) {
      lists.push(<option key={i} value={list[i].value}>{list[i].text}</option>)    
    }
    return lists;
}

const departmentData = async () =>{
  let response = await axios.get(`${base_URL}/department`);
  return response.data.body;
}

const departmentList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].id}>{list[i].departmentName}</option>)    
  }
  return lists;
}

const committeeData = async () =>{
  let response = await axios.get(`${base_URL}/committee`);
  return response.data.body;
}

const committeeList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].id}>{list[i].title}</option>)    
  }
  return lists;
}

const empTypeData = async () =>{
  let response = await axios.get(`${base_URL}/empType`);
  return response.data.body;
}

const empTypeList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].id}>{list[i].type}</option>)    
  }
  return lists;
}

// const departmentList = (list) => {
//   let lists = [];
//   for (let i = 0; i < list.length; i++) {
//     lists.push(<MenuItem key={i} value={list[i].id}>{list[i].departmentName}</MenuItem>)    
//   }
//   return lists;
// }

const designationData = async () =>{
  let response = await axios.get(`${base_URL}/designation`);
  return response.data.body;
}

// this is important code used for custom selection
const designationList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].id}>{list[i].designation}</option>)    
  }
  return lists;
}

// this is code used for material UI selection
// const designationList = (list) => {
//   let lists = [];
//   for (let i = 0; i < list.length; i++) {
//     lists.push(<MenuItem  key={i} value={list[i].id}>{list[i].designation}</MenuItem >)    
//   }
//   return lists;
// }

const genderData = async () =>{
  let response = await axios.get(`${base_URL}/gender`);
  return response.data.body;
}

// this is important code used for custom selection
const genderList = (list) => {
  let lists = [];
  for (let i = 0; i < list.length; i++) {
    lists.push(<option key={i} value={list[i].id}>{list[i].gender}</option>)    
  }
  return lists;
}

export {
  roleData, rolesList,
  departmentData, departmentList,
  designationData, designationList,
  genderData, genderList,
  committeeList,committeeData,
  empTypeData,empTypeList
}


// // import axios from "axios";
// // import { base_URL } from "../middleware/config";

// // const roles = [
// //     // {text: "Admin" , value : 1},
// //     // {text: "Packing staff" , value : 2},
// //     // {text: "Delivery staff" , value : 3},
// //     // {text: "Verification staff" , value : 4},
// //     // {text: "Customer" , value : 5},
// //     // {text: "Merchant" , value : 6},
// //     // {text: "Merchant staff" , value : 7}
// // ];

// // const role = [];
// // for(let i=0;i<roles.length;i++){
// //     role.push(<option key={i} value={roles[i].value}>{roles[i].text}</option>)
// // }

// // export {
// //     roles, role
// // };
