import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_URL } from "../middleware/config";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Modal from "../components/Modal";
import "../css/commonControl.css";
import "../css/contact.css";
import CallModelSpinner from "../components/callModelSpinner";
import ReactHTMLParser from "html-react-parser";
import DOMPurify from "dompurify";

const Contact = () => {
  const auth = localStorage.getItem("key");
  const [tableColumn, setTableColumn] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [editData, setEditData] = useState([]);
  const [token, setToken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const close = () => {
    setEditModel(false);
  };

  const getContact = async () => {
    await axios.get(`${base_URL}/contact`).then((result) => {
      setTableColumn(result.data.body);
    });
  };

  //function to edit
  const editFileChange = async (id) => {
    setIsLoading(true);
    await axios.get(`${base_URL}/contact?id=${id}`).then((getData) => {
      setEditData(getData.data.body);
    });
    setEditModel(true);
    setIsLoading(false);
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    let finalData = { ...editData, token: token };
    let response = await axios.put(`${base_URL}/contact`, finalData);
    if (response.data.status === 200) {
      alert(`${response.data.message}`);
      navigate("/contact");
      setIsLoading(false);
      window.location.reload(false);
    } else {
      alert(`${response.data.message}`);
      setIsLoading(false);
      return;
    }
  };

  useEffect(() => {
    getContact();
    setToken(JSON.parse(auth).token);
    if (!auth) navigate("/");
    //eslint-disable-next-line
  }, []);

  return (
    <div className="contact">
      <h2 className="title">Contact Entry</h2>
      <table className="contactForm" id="contactData">
        <tr>
          <td>
            <small>In case to change contact information. Press Edit.</small>
          </td>
        </tr>
      </table>
      <div className="tblWrapper wrapperHeightContact">
        <table className="dataTable">
          <thead>
            <tr>
              <th> Title </th>
              <th> Phone </th>
              <th> Email </th>
              <th> Address </th>
              <th> City </th>
              <th> State </th>
            </tr>
          </thead>
          <tbody>
            {tableColumn &&
              tableColumn.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{data.title} </td>
                    <td>{data.phone} </td>
                    <td>{data.email} </td>
                    <td>{data.address}</td>
                    <td>{data.city}</td>
                    <td>{data.state}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <table className="dataTable">
          <thead>
            <tr>
              <th> Country </th>
              <th> Pin Code </th>
              <th> Facebook Link </th>
              <th> Instagram Link </th>
              <th> Linkdin Link </th>
              <th> Twitter Link </th>
            </tr>
          </thead>
          <tbody>
            {tableColumn &&
              tableColumn.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{data.country}</td>
                    <td>{data.pinCode}</td>
                    <td>{data.facebookLink}</td>
                    <td>{data.instagramLink}</td>
                    <td>{data.linkdinLink}</td>
                    <td>{data.twitterLink}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <table className="dataTable">
          <thead>
            <tr>
              <th> Campus Introduction </th>
            </tr>
          </thead>
          <tbody>
            {tableColumn &&
              tableColumn.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td className="ckEdit">{ReactHTMLParser(DOMPurify.sanitize(data.campusIntroduction, {
                      USE_PROFILES: { html: true },
                    }))}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <table className="dataTable">
          <tr>
            <div className="btnFlex">
              <button
                variant="contained"
                onClick={() => editFileChange(1)}
                className="btn-gbl-blue btn contactEditBtn"
                type="button"
              >
                Edit contact info
              </button>
            </div>
          </tr>
        </table>
      </div>
      <Modal
        width="fit-content"
        border="none"
        modal={editModel}
        title="Edit Contact"
        close={close}
        content={
          <form onSubmit={handleFormSubmit}>
            <table className="contactTable">
              <tr>
                <td>
                  <small className="inputLabels">Title:-</small>
                  <input className="text" type="text" placeholder="Title*" required value={editData.title} onChange={(e) => setEditData({ ...editData, title: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">Phone:-</small>
                  <input className="text" type="text" placeholder="Phone*" required value={editData.phone} onChange={(e) => setEditData({ ...editData, phone: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">Email:-</small>
                  <input className="text" type="text" placeholder="email*" required value={editData.email} onChange={(e) => setEditData({ ...editData, email: e.target.value })} />
                </td>
              </tr>
              <tr>
                <td>
                  <small className="inputLabels">Address</small>
                  <input className="text" type="text" placeholder="Address*" required value={editData.address} onChange={(e) => setEditData({ ...editData, address: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">City</small>
                  <input className="text" type="text" placeholder="City*" required value={editData.city} onChange={(e) => setEditData({ ...editData, city: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">State</small>
                  <input className="text" type="text" placeholder="State*" required value={editData.state} onChange={(e) => setEditData({ ...editData, state: e.target.value })} />
                </td>
              </tr>
              <tr>
                <td>
                  <small className="inputLabels">Country</small>
                  <input className="text" type="text" placeholder="Country*" required value={editData.country} onChange={(e) => setEditData({ ...editData, country: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">Pin Code</small>
                  <input className="text" type="text" placeholder="Pin_Code*" required value={editData.pinCode} onChange={(e) => setEditData({ ...editData, pinCode: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">Facebook Link</small>
                  <input className="text" type="text" placeholder="facebookLink*" required value={editData.facebookLink} onChange={(e) => setEditData({ ...editData, facebookLink: e.target.value })} />
                </td>
              </tr>
              <tr>
                <td>
                  <small className="inputLabels">Instagram Link</small>
                  <input className="text" type="text" placeholder="instagramLink*" required value={editData.instagramLink} onChange={(e) => setEditData({ ...editData, instagramLink: e.target.value, })} />
                </td>
                <td>
                  <small className="inputLabels">Linkdin Link</small>
                  <input className="text" type="text" placeholder="linkdinLink*" required value={editData.linkdinLink} onChange={(e) => setEditData({ ...editData, linkdinLink: e.target.value })} />
                </td>
                <td>
                  <small className="inputLabels">Twitter Link</small>
                  <input className="text" type="text" placeholder="twitterLink*" required value={editData.twitterLink} onChange={(e) => setEditData({ ...editData, twitterLink: e.target.value })} />
                </td>
              </tr>
            </table>
            <div className="campusIntroduction">
              <small className="inputLabels">Campus Introduction</small>
              {/* <textarea className="text" type="text" placeholder="campusIntroduction*" required value={editData.campusIntroduction} onChange={(e) => setEditData({ ...editData, campusIntroduction: e.target.value }) } /> */}
              {/* <CKEditor editor = { ClassicEditor } className="text" type="text" placeholder="campusIntroduction*" data={editData.campusIntroduction} onChange={(e) => setEditData({ ...editData, campusIntroduction: e.target.value }) } /> */}

            </div>
            <div className="contact-ck--container">
              <CKEditor
                data={`${editData.campusIntroduction}`}
                editor={ClassicEditor}

                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditData({ ...editData, campusIntroduction: data })
                  // console.log({ event, editor, data });
                }}

              />
            </div>
            {isLoading ? <CallModelSpinner /> : ""}
          </form>
        }
        actions={
          <button className="btn-gbl-green btn contact-form-btn" type="submit" onClick={handleFormSubmit}> Submit </button>
        }
      />
    </div>
  );
};

export default Contact;
