import React from 'react';

const Headder = () => {
  return (
    <header className='headder'>
        <p>Welcome to Thakur Ram Multiple Campus Admin</p>
    </header>
  )
}

export default Headder;
