import React from 'react'
import LoadingSpinner from '../components/LoadingSpinner';
import '../css/callSpinner.css'

const CallSpinner = () => {
  return (
    <div className='callSpinner'>
        <LoadingSpinner />
    </div>
    
  )
}

export default CallSpinner