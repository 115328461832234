import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { base_URL, Image_URL } from '../middleware/config';
import { departmentData, departmentList } from "../components/dropdown";
import { useNavigate } from "react-router-dom";
import '../css/noticeBoard.css';
import '../css/commonControl.css';
import Modal from '../components/Modal';
import CallSpinner from '../components/callSpinner';
import CallModelSpinner from '../components/callModelSpinner';


const NoticBoard = () => {
  const auth = localStorage.getItem('key');
  const [token, setToken] = useState([]);
  const [formData, setFormData] = useState({
    description: '',
    lastDate: '',
    noticeFiles: '', // for fileName
    departmentId: '',
    isFlash: '',
    token: JSON.parse(auth).token
  });
  const [isLoading, setIsLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [tableColumns, setColumns] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [files, setFiles] = useState([]);
  const [orignalFileName, setOrignalFileName] = useState([]);
  const [editData, setEditData] = useState([]);
  const [isEdit, setIsEdit] = useState([false]);
  const [editModel, setEditModel] = useState(false);
  // const [selectedOption, setSelectedOption] = useState('');

  const close = () => {
    setEditModel(false);
  }

  const getDepartmentList = async () => {
    var result = await departmentData();
    setDepartment(result);
  }

  const getNoticeById = async (id) => {
    setIsLoading(true);
    await axios.get(`${base_URL}/notice?depId=${id}`).then((getData) => {
      setColumns(getData.data.body);
      setTableFilter(getData.data.body);
    });
    setIsLoading(false);
  }

  // const setFlashNews = (event) => {
  //   setSelectedOption(event.target.value);
  // }

  let navigate = useNavigate();

  useEffect(() => {
    getDepartmentList();
    getNoticeById();
    setToken(JSON.parse(auth).token);
    if (!auth) navigate('/');
    //eslint-disable-next-line
  }, []);

  const filterData = (e) => {
    var val = e.target.value;
    if (e.target.value !== "") {
      const filterTable = tableColumns.filter(
        e => e.description.toLowerCase().includes(val) ||
          e.lastDate.toLowerCase().includes(val) ||
          e.department.toLowerCase().includes(val)
      );
      setTableFilter([...filterTable])
    } else setTableFilter([...tableColumns])
  }

  const handleFormSubmit = async () => {
    setIsLoading(true);
    const x = new FormData();
    for (let i = 0; i < files.length; i++) {
      x.append('files', files[i]);
    }
    let chkAuth = await axios.post(`${base_URL}/upload/chkAuth`, { token });
    if (chkAuth.data.status === 200) {
      await axios.post(`${base_URL}/upload`, x).then(async res => {
        let finalData = isEdit === true ? { ...editData, noticeFiles: res.data.body.files, token: token, orignalFileName: orignalFileName } : { ...formData, noticeFiles: res.data.body.files, orignalFileName: orignalFileName };
        let response = isEdit === true ? await axios.put(`${base_URL}/notice`, finalData) : await axios.post(`${base_URL}/notice`, finalData);
        if (response.data.status === 200) {
          alert(`${response.data.message}`);
          navigate('/notice');
          setIsLoading(false);
          setFormData(null)
          window.location.reload(false);
          //   setTimeout(() => {
          //       setIsLoading(false);
          //     }, 3000);
        }
        else {
          alert(`${response.data.message}`);
          setIsLoading(false);
          setFormData(null)
          return;
        };
      });
    }
    setIsEdit(false);
    setFormData(null)
  }

  // handle file inputs
  const handleFileChange = e => {
    if (e.target.files.length > 0) {
      let files = e.target.files;
      let x = []; //All files 
      let y = []; //Orignal name
      for (let i = 0; i < files.length; i++) {
        y.push(files[i].name);
        x.push(files[i]);
      }
      setOrignalFileName(y);
      setFiles(x);
    }
  }

  //function to edit
  const editFileChange = async (id) => {
    setIsLoading(true);
    await axios.get(`${base_URL}/notice?id=${id}`).then((getData) => {
      setEditData(getData.data.body);
    });
    setIsEdit(true);
    setEditModel(true);
    setIsLoading(false);
  }

  //function to Delete
  const deleteFileChange = async (id) => {
    if (window.confirm("Are you sure to delete") === true) {
      setIsLoading(true);
      await axios.put(`${base_URL}/notice/delete?id=${id}`, { token: token }).then(result => {
        let code = result.data.status;
        let message = result.data.message;
        if (code === 200) {
          alert(message);
          setIsLoading(false);
          window.location.reload(false);
        }
        else {
          alert(message)
          setIsLoading(false);
        };
      });
      navigate('/notice');
    }
    else return;
  }

  return (
    <div>

      {/* modal, width, title, close, content, actions  */}
      <Modal width='fit-content' border='none' modal={editModel} title='Edit Notice' close={close} content={<form onSubmit={handleFormSubmit}>
        <table>
          <tr>
            <td>
              <small className="inputLabels">Description:-</small>
              <input className='text' type="text" placeholder='Description*' required value={editData.description} onChange={(e) => setEditData({ ...editData, description: e.target.value })} />
            </td>
            <td>
              <small className="inputLabels">Last Date</small>
              <input className='text' type="date" required value={editData.lastDate} onChange={e => setEditData({ ...editData, lastDate: e.target.value })} />
            </td>
          </tr>
          <tr>
            <td>
              <small className="inputLabels">Department</small>
              <select className="from_dropdown text" value={editData.departmentId} onChange={(e) => setEditData({ ...editData, departmentId: e.target.value })} id="departmentId">
                <option value="">Select Department</option>
                {departmentList(department)}
              </select>
            </td>
            <td>
              <small className="inputLabels">Flash News</small>
              <select defaultValue={editData.isFlash} className="from_dropdown text" onChange={(e) => setEditData({ ...editData, isFlash: e.target.value })} id="isFlash">
                <option key={0} value={0}>No</option>
                <option key={1} value={1}>Yes</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <small className="inputLabels">Notice Files</small>
              <label htmlFor='files' className='file'>
                <input className='text' type='file' id='files' name='files' multiple onChange={handleFileChange} accept="application/pdf, image/png, image/jpg, image/jpeg" />
              </label>
            </td>
          </tr>
        </table>
        {isLoading ? <CallModelSpinner /> : ''}
      </form>} actions={<button className='btn-gbl-green btn' type='submit' onClick={handleFormSubmit}>Submit</button>} />

      {/* Main Entry Form */}
      <h2 className='title'>Notice Board</h2>
      <table className='noticeFormTable'>
        <tr>
          <td>
            <small className="inputLabels">Description:-</small>
            <input className='text' type="text" placeholder="Enter Description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} id="description"></input>
          </td>
          <td>
            <small className="inputLabels">Last Date</small>
            <input className='text dateInput' type="date" placeholder="Enter Last Date" value={formData.lastDate} onChange={(e) => setFormData({ ...formData, lastDate: e.target.value })} id="lastDate"></input>
          </td>
          <td>
            <small className="inputLabels">Department</small>
            <select className="from_dropdown text" value={formData.departmentId} onChange={(e) => setFormData({ ...formData, departmentId: e.target.value })} id="departmentId">
              <option value="">Select Department</option>
              {departmentList(department)}
            </select>
          </td>
          <td>
            <small className="inputLabels">Flash News</small>
            <select className="from_dropdown text" value={formData.isFlash} onChange={(e) => setFormData({ ...formData, isFlash: e.target.value })} id="isFlash">
              <option key={0} value={0}>No</option>
              <option key={1} value={1}>Yes</option>
            </select>
          </td>
          <td>
            <small className="inputLabels">Notice Files</small>
            <label htmlFor='files' className='file'>
              <input className='text' type='file' id='files' name='files' multiple onChange={handleFileChange} accept="application/pdf, image/png, image/jpg, image/jpeg" />
            </label>
            {/* <input className='image_file text' type="file" placeholder="Enter Picture" value={formData.profilePic} onChange={(e) => setFormData({ ...formData, profilePic: e.target.value })} id="profilePic" accept="image/png, image/jpg, image/jpeg"></input>           */}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <button onClick={handleFormSubmit} className="btn-gbl-green btn noticeEntryBtn" type="button">Make Entry</button>
          </td>
        </tr>
      </table>

      <hr />
      <div className='search'>
        <input type="text" className='searchBar' id='searchValue' placeholder='Search' onChange={filterData}></input>
      </div>
      <p>Please select your Department:</p>
      <div className='radioBtn'>
        <label for="All"> <input type="radio" id="All" onChange={() => getNoticeById()} name="fav_faculty" value="" /> All</label>
        <label for="B.Sc."> <input type="radio" id="B.Sc." onChange={() => getNoticeById(1)} name="fav_faculty" value="1" /> B.Sc.</label>
        <label for="B.I.T"> <input type="radio" id="B.I.T" onChange={() => getNoticeById(2)} name="fav_faculty" value="2" /> B.I.T</label>
        <label for="B.B.A"> <input type="radio" id="B.B.A" onChange={() => getNoticeById(3)} name="fav_faculty" value="B.B.A" /> B.B.A </label>
        <label for="B.B.S."> <input type="radio" id="B.B.S." onChange={() => getNoticeById(4)} name="fav_faculty" value="B.B.S." /> B.B.S. </label>
        <label for="B.A"> <input type="radio" id="B.A" onChange={() => getNoticeById(5)} name="fav_faculty" value="B.A" /> B.A </label>
        <label for="B.E"> <input type="radio" id="B.E" onChange={() => getNoticeById(6)} name="fav_faculty" value="B.E" /> B.E </label>
        <label for="M.B.S"> <input type="radio" id="M.B.S" onChange={() => getNoticeById(7)} name="fav_faculty" value="M.B.S" /> M.B.S </label>
        <label for="M.A"> <input type="radio" id="M.A" onChange={() => getNoticeById(8)} name="fav_faculty" value="M.A" /> M.A </label>
        <label for="M.Ed"> <input type="radio" id="M.Ed" onChange={() => getNoticeById(9)} name="fav_faculty" value="M.Ed" /> M.Ed </label>
        <label for="Exam"> <input type="radio" id="Exam" onChange={() => getNoticeById(10)} name="fav_faculty" value="Exam" /> Exam </label>
        <label for="Administration"> <input type="radio" onChange={() => getNoticeById(11)} id="Administration" name="fav_faculty" value="Administration" /> Administration </label>
        <label for="Admission"> <input type="radio" id="Admission" onChange={() => getNoticeById(12)} name="fav_faculty" value="Admission" /> Admission </label>
        <label for="Public"> <input type="radio" id="Public" onChange={() => getNoticeById(13)} name="fav_faculty" value="Public" /> Downloades </label>
      </div>

      <hr />
      <div className='tblWrapper wrapperHeightNotice'>
        <table className='dataTable'>
          <thead>
            <tr>
              <th> S.No </th>
              <th> Description </th>
              <th> Last Date </th>
              <th> File </th>
              <th> Department </th>
              <th> Faculty </th>
            </tr>
          </thead>
          <tbody>
            {tableFilter && tableFilter.map((data, index) => {
              console.log(data.isFlash,'flash')
              const className = data.isFlash === 1 ? "colorTable" : ""
              return (
                <tr className={className} key={data.id}>
                  <td>{index + 1}</td>
                  <td>{data.description} </td>
                  <td>{data.lastDate}</td>
                  <td><img className="tableImage" src={`${Image_URL}/${data.fileName.match(".pdf") ? data.fileName = "pdf.png" : data.fileName = (data.fileName.split(',').length > 1 ? "multiple_file.png" : data.fileName)}`} alt={data.fileName} /></td>
                  <td>{data.department} ({data.shortName})</td>
                  <td>
                    <div className='btnFlex'>
                      <button onClick={() => editFileChange(data.id)} className="btn-gbl-blue btn" type="button">Edit</button>
                      <button variant='contained' onClick={() => deleteFileChange(data.id)} className="btn-gbl-red btn" type="button">Delete</button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {isLoading ? <CallSpinner /> : ''}
    </div>
  )
}

export default NoticBoard;
