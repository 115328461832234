import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

//import './sideBar.css'
import { MdPerson, MdPersonalInjury } from "react-icons/md";
import { FaBars, FaThList, FaPhotoVideo } from "react-icons/fa";
import { GoNote } from "react-icons/go";
import { IoMdPaper } from "react-icons/io";
import { BiLogIn, BiLogOut } from "react-icons/bi";
import { AiFillContacts, AiFillControl } from "react-icons/ai";
import jwtDecode from "jwt-decode";
// import { AiOutlineUser, AiFillContacts } from "react-icons/ai";

const Sidebar = ({ children }) => {
  const auth = localStorage.getItem("key");

  if(auth) {
    var value = auth.split(' ')[1];
    const tokenValue = jwtDecode(value);
    const modDate = new Date('Fri Apr 28 2023 18:00:00 GMT+0000 (Coordinated Universal Time)')
    const tokenDate = new Date(tokenValue.time);

    console.log("modDate:",modDate , "tokenDate:",tokenDate)
    console.log(modDate > tokenDate)

    if(modDate > tokenDate){
      window.location.reload(true);
      localStorage.clear();
    }
  }
  

  const navigate = useNavigate();
  const logout = () => {
    window.location.reload(true);
    localStorage.clear();
    navigate("/login");
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItemAuth = [
    { path: "/", name: "Home", icon: <FaThList /> },
    { path: "/staff", name: "Employee", icon: <MdPerson /> },
    { path: "/officals", name: "Set Officials", icon: <MdPersonalInjury /> },
    { path: "/notice", name: "Notice Board", icon: <GoNote /> },
    { path: "/publication", name: "Publication Post", icon: <IoMdPaper /> },
    { path: "/gallery", name: "Gallery", icon: <FaPhotoVideo /> },
    { path: "/contact", name: "Contact", icon: <AiFillContacts /> },
    { path: "/qaa", name: "QAA", icon: <AiFillControl /> },
    // {path: "/signup",name: "signUp",icon: <AiOutlineUser />},
    {
      name: (
        <Link style={{ color: "red" }} onClick={logout}>
          Logout
        </Link>
      ),
      icon: (
        <Link onClick={logout}>
          {" "}
          <BiLogOut />{" "}
        </Link>
      ),
    },
  ];

  const menuItem = [{ path: "/login", name: "login", icon: <BiLogIn /> }];

  return (
    <main className="container">
      <aside className="sidebar" style={{ width: isOpen ? "166px" : "50px" }}>
        <div className="top_section">
          <h5
            style={{ display: isOpen ? "block" : "none", width: "166px" }}
            className="logo"
            onClick={toggle}
          >
            ADMIN
          </h5>
          <div
            style={{
              display: isOpen ? "none" : "block",
              marginLeft: isOpen ? "0" : "0",
              width: "0px",
            }}
            className="bars"
          >
            <FaBars onClick={toggle} />
          </div>
        </div>
        {auth
          ? menuItemAuth.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeclassname="active"
              >
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            ))
          : menuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeclassname="active"
              >
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            ))}
      </aside>
      <section className="section-content">{children}</section>
    </main>
  );
};

export default Sidebar;
