import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { base_URL, Image_URL} from '../middleware/config';
import { departmentData, departmentList } from "../components/dropdown";
import { useNavigate } from "react-router-dom";
import '../css/publicationPost.css';
import '../css/commonControl.css';
import Modal from '../components/Modal';
import CallSpinner from '../components/callSpinner';
import CallModelSpinner from '../components/callModelSpinner';

export const PublicationPost = () => {
  const auth = localStorage.getItem('key');
  const [token , setToken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData,setFormData] = useState({
    description: '',
    fileName: '',
    publishDate: '',
    aurthor: '',
    departmentId: '',
    about: '',
    token:JSON.parse(auth).token
  });

  const [department, setDepartment] = useState([]);
  const [tableColumns, setColumns] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [files, setFiles] = useState([]);
  const [orignalFileName , setOrignalFileName] = useState([]);
  const [editData, setEditData] = useState([]);
  const [isEdit, setIsEdit] = useState([false]);
  const [editModel, setEditModel] = useState(false);

  const close = () => {
    setEditModel(false);
  }

  const getDepartmentList = async () => {
    var result = await departmentData();
    setDepartment(result);
  }
  const getPublication = async () => {
    setIsLoading(true);
    await axios.get(`${base_URL}/publication`).then((getData) => {
      setColumns(getData.data.body);
      setTableFilter(getData.data.body);
    });
    setIsLoading(false);
  }

  let navigate = useNavigate();

  useEffect(()=>{
    getDepartmentList();
    getPublication();
    setToken(JSON.parse(auth).token);
    if(!auth) navigate('/');
      //eslint-disable-next-line
  },[]);

  const filterData = (e) => {  
    var val = e.target.value;
    if(e.target.value !== ""){  
      const filterTable = tableColumns.filter(
        e => e.description.toLowerCase().includes(val) || 
        e.fileName.toLowerCase().includes(val) ||
        e.publishDate.toLowerCase().includes(val) ||
        e.aurthor.toLowerCase().includes(val) ||
        e.department.toLowerCase().includes(val)
      );
      setTableFilter([...filterTable])
    } else setTableFilter([...tableColumns])
  }

  const handleFormSubmit = async () => {
    setIsLoading(true);
    const x = new FormData();
    for (let i = 0 ; i < files.length; i++) {
      x.append('files', files[i]);
    }
    let chkAuth = await axios.post(`${base_URL}/upload/chkAuth`,{token});
    if(chkAuth.data.status === 200) {
      await axios.post(`${base_URL}/upload`,x).then(async res =>{
        let finalData = isEdit === true ? { ...editData, fileName: res.data.body.files, token:token, orignalFileName: orignalFileName } : { ...formData, fileName: res.data.body.files, orignalFileName: orignalFileName };
        let response  = isEdit === true ? await axios.put(`${base_URL}/publication`, finalData) : await axios.post(`${base_URL}/publication`, finalData);      
        if(response.data.status === 200) {
          alert(`${response.data.message}`);
          navigate('/publication');
          setIsLoading(false);
          window.location.reload(false);
        }
        else {
          alert(`${response.data.message}`);
          setIsLoading(false);
          return;
        };
      });
    }
    setIsEdit(false);
  }

  // handle file inputs
  const handleFileChange = e => {
    if(e.target.files.length > 0) {
      let files = e.target.files;
      let x = [];
      let y = [];
      for(let i = 0 ; i < files.length; i++) {
        y.push(files[i].name);
        x.push(files[i]);
      }
      setOrignalFileName(y);
      setFiles(x);
    }
  }

  //function to edit
const editFileChange = async (id) =>{
  setIsLoading(true);
  await axios.get(`${base_URL}/publication?id=${id}`).then((getData) => {
    setEditData(getData.data.body);
  });
  setIsEdit(true);  
  setEditModel(true); 
  setIsLoading(false); 
}

//function to Delete
const deleteFileChange = async (id) =>{
  if(window.confirm("Are you sure to delete") === true){
    setIsLoading(true);
    await axios.put(`${base_URL}/publication/delete?id=${id}`,{token:token}).then(result=>{
      let code = result.data.status;
      let message = result.data.message;
      if(code === 200) {
        alert(message);
        setIsLoading(false);
        window.location.reload(false);
      }
      else {
        alert(message);
        setIsLoading(false);
      };
    });
    navigate('/publication');
  }
  else return;
}

  return (
    <div>
      <Modal width='fit-content' border='none' modal={editModel} title='Edit publication' close={close} content={<form onSubmit={handleFormSubmit}>
      <table>
        <tr>
            <td>
              <small className = "inputLabels">Description:-</small>
              <input className='text' type="text" placeholder="Enter Description" value={editData.description} onChange={(e) => setEditData({ ...editData, description: e.target.value })} id="description"></input>
            </td>
            <td>
              <small className = "inputLabels">Aurthor:-</small>
              <input className='text' type="text" placeholder="Enter aurthor name" value={editData.aurthor} onChange={(e) => setEditData({ ...editData, aurthor: e.target.value })} id="aurthor"></input>
            </td>
            <td>
              <small className = "inputLabels">About:-</small>
              <input className='text' type="text" placeholder="Enter brief about" value={editData.about} onChange={(e) => setEditData({ ...editData, about: e.target.value })} id="about"></input>
            </td>
        </tr>
        <tr>
        <td>
          <small className = "inputLabels">Publish Date</small>
          <input className='text' type="date" placeholder="Enter publish Date" value={editData.publishDate} onChange={(e) => setEditData({ ...editData, publishDate: e.target.value })} id="publishDate"></input>
        </td>
        <td>
          <small className = "inputLabels">Department</small>
          <select className="from_dropdown text" value={editData.departmentId} onChange={(e) => setEditData({ ...editData, departmentId: e.target.value })} id="departmentId">
              <option value="">Select Department</option>
              {departmentList(department)}
          </select>
        </td>
        <td>
          <small className = "inputLabels">Publish Files</small>
          <label htmlFor='files' className='file'>
            <input className='text' type='file' id='files' name='files' multiple onChange={handleFileChange} accept="application/pdf, image/png, image/jpg, image/jpeg" />
          </label>
        </td>
        </tr>
        </table>
        { isLoading ? <CallModelSpinner/> : '' }
      </form>} actions={<button className='btn-gbl-green btn' type='submit' onClick={handleFormSubmit}>Submit</button>} />

      {/* Main entry form */}
      <h2 className='title'>Publication</h2>
      <table className='pubFormTable'>
        <tr>
          <td>
            <small className = "inputLabels">Description:-</small>
            <input className='text' type="text" placeholder="Enter Description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} id="description"></input>
          </td>
          <td>
            <small className = "inputLabels">Aurthor:-</small>
            <input className='text' type="text" placeholder="Enter aurthor name" value={formData.aurthor} onChange={(e) => setFormData({ ...formData, aurthor: e.target.value })} id="aurthor"></input>
          </td>
          <td>
            <small className = "inputLabels">About:-</small>
            <input className='text' type="text" placeholder="Enter brief about" value={formData.about} onChange={(e) => setFormData({ ...formData, about: e.target.value })} id="about"></input>
          </td>
        </tr>
        <tr>
          <td>
            <small className = "inputLabels">Publish Date</small>
            <input className='text dateInput' type="date" placeholder="Enter publish Date" value={formData.publishDate} onChange={(e) => setFormData({ ...formData, publishDate: e.target.value })} id="publishDate"></input>
          </td>
          <td>
            <small className = "inputLabels">Department</small>
            <select className="from_dropdown text" value={formData.departmentId} onChange={(e) => setFormData({ ...formData, departmentId: e.target.value })} id="departmentId">
                <option value="">Select Department</option>
                {departmentList(department)}
            </select>
          </td>
          <td>
            <small className = "inputLabels">Publish Files</small>
            <label htmlFor='files' className='file'>
              <input className='text' type='file' id='files' name='files' multiple onChange={handleFileChange} accept="application/pdf, image/png, image/jpg, image/jpeg" />
            </label>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <button onClick={handleFormSubmit} className="btn-gbl-green btn pubEntryBtn" type="button">Make Entry</button>
          </td>
        </tr>
      </table>                  
      <hr/>
      <div className='search'>
        <input type="text" className='searchBar' id='searchValue' placeholder='Search'  onChange={filterData}></input>
      </div>
      <div className='tblWrapper wrapperHeightPublication'>
        <table className='dataTable'>
          <thead>
              <tr>
                <th> Id </th>
                <th> Description </th>
                <th> Aurthor </th>
                <th> About </th>
                <th> File </th>
                <th> Department </th>
                <th> Date of Publish </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {tableFilter && tableFilter.map((data, index)=>{
                return(
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data.description} </td>
                    <td>{data.aurthor}</td>
                    <td>{data.about}</td>
                    {/* <td><img className="tableImage" src={`${Image_URL}/${data.fileName.match(".pdf") ? data.fileName = "pdf.png" : data.fileName}`} alt='file'/></td> */}
                    <td><img className="tableImage" src={`${Image_URL}/${data.fileName.match(".pdf") ? data.fileName = "pdf.png"  : data.fileName =( data.fileName.split(',').length > 1 ? "multiple_file.png" :  data.fileName) }`} alt={data.fileName}/></td>
                    <td>{data.department} ({data.shortName})</td>
                    <td>{data.publishDate}</td>
                    <td>
                      <div className='btnFlex'>
                        <button onClick={() =>editFileChange(data.id) } className="btn-gbl-blue btn" type="button">Edit</button>                         
                        <button variant='contained' onClick={()=>deleteFileChange(data.id)} className="btn-gbl-red btn" type="button">Delete</button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
        </table>
      </div>
      { isLoading? <CallSpinner/>:''}
    </div>
  )
}

export default PublicationPost;
