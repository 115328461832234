import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Sidebar from './components/sideBar';
import './css/sideBar.css';
import Login from './pages/login';
// import SignUp from './pages/signUp';
import Staff from './pages/staffControl';
import Officals from './pages/setOfficals';
import NoticBoard from './pages/noticBoard';
import PublicationPost from './pages/publicationPost';
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import QAA from './pages/QAA';
import Home from './pages/home';
import Footer from './components/Footer';
import Headder from './components/Headder';
import PrivateComponent from './components/private';

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <Headder />
      <BrowserRouter>
        <Sidebar>
          <Routes>
            <Route element={<PrivateComponent />}>
              <Route path='/' element={<Home />} />
              <Route path='/staff' element={<Staff />} />
              <Route path='/officals' element={<Officals />} />
              <Route path='/notice' element={<NoticBoard />} />
              <Route path='/publication' element={<PublicationPost />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/qaa' element={<QAA />} />
              {/* <Route path='/signup' element={ <SignUp/>}/> */}
            </Route>
            <Route path='/login' element={<Login />} />
          </Routes>
        </Sidebar>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
