import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_URL } from "../middleware/config";
//import { Tumbnails_URL } from "../middleware/config";
import { useNavigate } from "react-router-dom";
import "../css/commonControl.css";
import "../css/gallery.css";
import Modal from "../components/Modal";
import CallModelSpinner from "../components/callModelSpinner";
// import Resizer from "react-image-file-resizer";
import resizeImage from "../utils";
import { v4 as uuidv4 } from 'uuid'
// Resizer.imageFileResizer(
//   file, // Is the file of the image which will resized.
//   maxWidth, // Is the maxWidth of the resized new image.
//   maxHeight, // Is the maxHeight of the resized new image.
//   compressFormat, // Is the compressFormat of the resized new image.
//   quality, // Is the quality of the resized new image.
//   rotation, // Is the degree of clockwise rotation to apply to uploaded image.
//   responseUriFunc, // Is the callBack function of the resized new image URI.
//   outputType, // Is the output type of the resized new image.
//   minWidth, // Is the minWidth of the resized new image.
//   minHeight // Is the minHeight of the resized new image.
// );

function getRandomImage(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const Gallery = () => {
  const auth = localStorage.getItem("key");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    token: JSON.parse(auth).token,
  });
  const [displayImage, setDisplayImage] = useState([]);
  // const [imageData, setImageData] = useState([]);
  const [token, setToken] = useState([]);
  const [files, setFiles] = useState([]);
  const [thumbnailsFiles, setThumbnailsFiles] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const getImages = async () => {
    setIsLoading(true);
    await axios.get(`${base_URL}/gallery/all`).then((result) => {
      let data = result.data.body;
      let gallery = [];

      if (!data) return;

      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let galleryImage = {};
          galleryImage.id = data[i].id;
          galleryImage.title = data[i].title;
          galleryImage.date = data[i].date;
          galleryImage.description = data[i].description
          galleryImage.images = data[i].images.toString().split(",");
          galleryImage.thumbnails = data[i].thumbnails.toString().split(",");
          gallery.push(galleryImage);
        }
        setDisplayImage(gallery);
      }
    });
    setIsLoading(false);
  };

  // const resizeFile = (file, type) =>
  //   new Promise((resolve) => {
  //     return Resizer.imageFileResizer(file, 300, 300, type, 100, 0, (uri) => { resolve(uri); }, "file");
  //   });

  // handle file inputs
  const handleFileChange = async (e) => {
    setIsLoading(true);
    if (e.target.files.length > 0) {
      var x = [];
      for (let file of e.target.files) x.push(file);
      setFiles(x);
      let y = [];
      for (let f of x) {
        try {
          // var t = f.type.split("/")[1].toString();
          var t = f.type.toString();
          var result = await resizeImage(400, 400, f, t);
          y.push(result.file);
        } catch (e) {
          console.log("error ", e);
        }
      }
      setThumbnailsFiles(y);
    }
    setIsLoading(false);
  };

  //function to open model
  const openModel = async () => {
    setIsLoading(true);
    setEditModel(true);
    setIsLoading(false);
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);

    const x = new FormData();
    const uniqNames = []
    for (const file of files) {
      const uniqueFileName = `${uuidv4()}-${file.name}`
      x.append("files", file, uniqueFileName)
      uniqNames.push(uniqueFileName)
    }
    // files.forEach((file, index) => {
    //   x.append("files", file.name)
    // })
    // for (let i = 0; i < files.length; i++) {
    //   const uniqueId = uuidv4()
    //   x.append("files", files[i], `${uniqueId}-${files[i].name}`);
    //   // x.append("files", files[i], `${files[i].lastModified}.${files[i].name.split('.').pop()}`);
    // }

    const y = new FormData();
    for (const [index, thumb] of thumbnailsFiles.entries()) {
      if (files[index].name === thumb.name) {
        let fileName = `thumb-${uniqNames[index]}`;
        y.append("files", thumb, fileName);
      }
    }
    // for (let j = 0; j < thumbnailsFiles.length; j++) {
    //   if (files[j].name === thumbnailsFiles[j].name) {
    //     let fileName = `thumb-${uniqNames[j]}`;
    //     y.append("files", thumbnailsFiles[j], fileName);
    //     // let fileName = `${files[j].lastModified}.${files[j].name.split('.').pop()}`
    //     //let newFileName = `thumb-${fileName}`;
    //     //y.append("files", thumbnailsFiles[j], newFileName);        
    //   }
    // }

    let chkAuth = await axios.post(`${base_URL}/upload/chkAuth`, { token });
    if (chkAuth.data.status === 200) {
      await axios.post(`${base_URL}/upload`, x).then(async (res) => {
        let finalData = { ...formData, image: res.data.body.files };
        let chkAuth = await axios.post(`${base_URL}/upload/chkAuth`, { token });
        if (chkAuth.data.status === 200) {
          // await axios.post(`${base_URL}/thumbnails`, y).then(async (res) => {
          await axios.post(`${base_URL}/upload`, y).then(async (res) => {
            let updateData = { ...finalData, thumbnails: res.data.body.files };
            let response = await axios.post(`${base_URL}/gallery`, updateData);
            if (response.data.status === 200) {
              alert(`${response.data.message}`);
              // if (window.confirm) {
              //   console.log("hello");
              // }
              // navigate("/gallery");
              setIsLoading(false);
              await getImages();
              window.location.reload(false);
            } else {
              alert(`${response.data.message}`);
              setIsLoading(false);
              await getImages();
              return;
            }
          });
        }
      });
    }
  };

  const close = () => {
    setEditModel(false);
  };

  useEffect(() => {
    getImages();
    setToken(JSON.parse(auth).token);
    if (!auth) navigate("/");
    // eslint-disable-next-line
  }, []);

  //function to Delete
  const deleteFileChange = async (id) => {
    // alert dialog is not working, keep this commented for a while.
    // if (window.confirm("Are you sure to delete") === true) {
    setIsLoading(true);
    await axios
      .put(`${base_URL}/gallery/delete?id=${id}`, { token: token })
      .then((result) => {
        let code = result.data.status;
        let message = result.data.message;
        if (code === 200) {
          alert(message);
          setIsLoading(false);
          window.location.reload(false);
        } else {
          alert(message);
          setIsLoading(false);
        }
      });
    // just refresh the data, do not reload the page after deleting
    await getImages()

  };

  return (
    <div className="gallery">
      {/* <button onClick={() =>editFileChange(data.id) } className="btn-gbl-blue" type="button">Edit</button>                         
        <button variant='contained' onClick={()=>deleteFileChange(data.id)} className="btn-gbl-red" type="button">Delete</button> */}

      <div>
        <Modal
          width="575px"
          border="none"
          modal={editModel}
          title="Add content to gallery"
          close={close}
          content={
            <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
              <table>
                <tr>
                  <td>
                    <small className="inputLabels">Title:-</small>
                    <input className="text" type="text" placeholder="Title*" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} id="title" />
                  </td>
                  <td>
                    <small className="inputLabels">Description:-</small>
                    <input className="text" type="text" placeholder="Description*" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value, })} id="description" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <small className="inputLabels">Occasion Date</small>
                    <input className="text" type="date" required value={formData.date} onChange={(e) => setFormData({ ...formData, date: e.target.value })} />
                  </td>
                  <td>
                    <small className="inputLabels">Picture</small>
                    <label htmlFor="files" className="file">
                      <input formEncType="multipart/form-data" className="text" type="file" id="files" name="files" multiple onChange={handleFileChange} accept="image/png, image/jpg, image/jpeg" />
                    </label>
                  </td>
                </tr>
              </table>
              {isLoading ? <CallModelSpinner /> : ""}
            </form>
          }
          actions={
            <button
              className="btn-gbl-green btn"
              type="submit"
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          }
        />
      </div>
      <div className="galleryCards">
        <button
          id="addItem"
          onClick={() => openModel()}
          className="btn-gbl-blue btn"
          type="button"
        >
          Add to Gallery
        </button>
        {displayImage.map((obj, index) => {
          return (
            <GalleryImage
              key={obj.id}
              obj={obj}
              deleteFileChange={deleteFileChange}
            />
          );
        })}
      </div>
    </div>
  );
};

function GalleryImage({ obj, deleteFileChange }) {
  const SECONDS = 2000;

  const imgCount = obj.images.length;
  const [currentImage, setCurrentImage] = useState(obj.thumbnails[0]);
  // const [currentThumbnails, setCurrentThumbnails] = useState(obj.thumbnails[0]);

  useEffect(() => {
    if (imgCount === 1) {
      return;
    }
    const interval = setInterval(() => {
      let currentRandom = getRandomImage(0, imgCount);
      setCurrentImage(() => {
        return obj.thumbnails[currentRandom];
      });
    }, SECONDS);
    return () => clearInterval(interval);
  });

  return (
    <div className="card">
      <i
        className="material-icons del"
        onClick={() => {
          deleteFileChange(obj.id);
        }}
      >
        delete
      </i>
      {/* {console.log(imgCount)} */}
      <div className="card_image">
        <img
          key={obj.id}
          className="imageDisplay"
          src={`${base_URL}/images/${currentImage}`}
          alt="Avatar"
        />
      </div>
      <div className="card_title title-white">
        <span>{obj.title}</span>
        <span>{obj.description}</span>
        <span>{obj.date}</span>
      </div>
    </div>
  );
}

export default Gallery;
