import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { base_URL, Image_URL } from '../middleware/config';
import { departmentData, departmentList, designationData, designationList, genderData, genderList,committeeList,committeeData, empTypeData,empTypeList } from "../components/dropdown";
import { useNavigate } from "react-router-dom";
import '../css/commonControl.css';
import '../css/staffControl.css';
import Modal from '../components/Modal';
import CallSpinner from '../components/callSpinner';
import CallModelSpinner from '../components/callModelSpinner';
import * as xlsx from 'xlsx';

const Staff = () => {
  const auth = localStorage.getItem('key');
  const [token , setToken] = useState([]);
  const [formData, setFormData] = useState({
    fullName : '',
    email : '',
    phone  : '',
    address : '',
    about : '',
    images : '', // for profile picture
    designationId : '',
    experience : '',
    departmentId : '',
    empTypeId: '',
    employeeId:'',
    committeeId:'',
    genderId:'',
    token:JSON.parse(auth).token
  });   
  const [department, setDepartment] = useState([]);
  const [committee, setCommittee] = useState([]);
  const [empList, setEmpList] = useState([])
  const [designation, setDesignation] = useState([]);
  const [gender, setGender] = useState([]);
  const [tableColumns, setColumns] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [files, setFiles] = useState([]);
  const [editData, setEditData] = useState([]);
  const [isEdit, setIsEdit] = useState([false]);
  const [editModel, setEditModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [excelFile, setExcelFile] = useState(null); 



  const close = () => {
    setEditModel(false);
  }

  const getStaff = async () => {
    await axios.get(`${base_URL}/staffs`).then((getData) => {
      setColumns(getData.data.body);
      setTableFilter(getData.data.body);
    })
  }

  const getDepartmentList = async () => {
    var result = await departmentData();
    setDepartment(result);
  }

  const getCommitteeList = async () => {
    var result = await committeeData();
    setCommittee(result);
  }

  const getEmpList = async () => {
    var result = await empTypeData();
    setEmpList(result);
  }

  const getDesignationList = async () => {
    var result = await designationData();
    setDesignation(result);
  }

  const getGenderList = async () =>{
    var result = await genderData();
    setGender(result);
  }

  let navigate = useNavigate();

  useEffect(()=>{
    getDepartmentList();
    getDesignationList();
    getGenderList();
    getCommitteeList();
    getEmpList();
    getStaff();
    setToken(JSON.parse(auth).token);
    if(!auth) navigate('/');
      //eslint-disable-next-line
  },[]);
    

  const handleFormSubmit = async () => {
    setIsLoading(true);
    const x = new FormData();
    for (let i = 0 ; i < files.length; i++) {
      x.append('files', files[i]);
    }
    let chkAuth = await axios.post(`${base_URL}/upload/chkAuth`,{token});
    if(chkAuth.data.status === 200) {
      await axios.post(`${base_URL}/upload`,x).then(async res =>{
        let finalData = isEdit === true ? { ...editData, images: res.data.body.files, token:token } : { ...formData, images: res.data.body.files };  
        let response  = isEdit === true ? await axios.put(`${base_URL}/staffs`, finalData) : await axios.post(`${base_URL}/staffs`, finalData);     
        if(response.data.status === 200) {
          alert(`${response.data.message}`);
          navigate('/staff');
          setIsLoading(false);
          window.location.reload(false);
        }
        else {
          alert(`${response.data.message}`);
          setIsLoading(false);          
          return;
        };
      });
    }
  }

  // handle file inputs
  const handleFileChange = e => {
    if(e.target.files.length > 0) {
        let files = e.target.files;
        let x = [];
        for(let i = 0 ; i < files.length; i++) {
        x.push(files[i]);
        }
        setFiles(x);
    }
  }

  //handle excel file
  const fileType = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  const excelFileChange = e =>{
    let xlslFile = e.target.files[0];
    if(e.target.files.length === 1){
      if(xlslFile && fileType.includes(xlslFile.type)){
        let reader = new FileReader();
        reader.readAsArrayBuffer(xlslFile);
        reader.onload = (e) =>{
          setExcelFile(e.target.result);
        }
      }else {
        setExcelFile(null);
        alert('You have not selected and excel file.');
      }
    }else alert('No file or More then one file selected.');
  }

  const handleXlsxSubmit = async (e) =>{
    if(excelFile === null) return;
    if(window.confirm("Are you sure to Upload") === true){
      setIsLoading(true);
      e.preventDefault();
      var excelData = [];
      if(excelFile !== null){
        const workBook = xlsx.read(excelFile,{type:'buffer'});
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        const finalExceldata = xlsx.utils.sheet_to_json(workSheet);
        const postData = {...excelData ,finalExceldata, token:token}
        let response = await axios.post(`${base_URL}/staffs/excelUpload`, postData);     
          if(response.data.status === 200) {
            alert(`${response.data.message}`);
            navigate('/staff');
            setIsLoading(false);
            window.location.reload(false);
          }
          else {
            alert(`${response.data.message}`);
            setIsLoading(false);          
            return;
          };
      }else{
        console.log('line 174 Error');
      }
    }
    else return;
  }

//function to edit
const editFileChange = async (id) => {
  setIsLoading(true);
  await axios.get(`${base_URL}/staffs?id=${id}`).then((getData) => {
    setEditData(getData.data.body);
  });
  setIsEdit(true);
  setEditModel(true);
  setIsLoading(false);
}

//function to Delete
const deleteFileChange = async (id) =>{
  if(window.confirm("Are you sure to delete") === true){
    setIsLoading(true);
    await axios.put(`${base_URL}/staffs/delete?id=${id}`,{token:token}).then(result=>{
      let code = result.data.status;
      let message = result.data.message;
      if(code === 200) {
        alert(message);
        setIsLoading(false);
        window.location.reload(false);
      }
      else {
        alert(message);
        setIsLoading(false);
      }
    });
    navigate('/staff');
  }
  else return;
}

const filterData = (e) => {  
  var val = e.target.value;
  if(e.target.value !== ""){  
    const filterTable = tableColumns.filter(
      e => e.shortName.toLowerCase().includes(val) || 
      e.fullName.toLowerCase().includes(val) || 
      e.email.toLowerCase().includes(val) || 
      e.phone.toLowerCase().includes(val) || 
      e.address.toLowerCase().includes(val) || 
      e.about.toLowerCase().includes(val) ||
      e.designation.toLowerCase().includes(val) ||
      e.department.toLowerCase().includes(val) ||
      e.experience.toLowerCase().includes(val)
    );
    setTableFilter([...filterTable])
  } else setTableFilter([...tableColumns])
}

// re create this using material ions link[https://www.youtube.com/watch?v=R_sbuXBCviQ&ab_channel=CodeforInterview] time start from 39:10
  return(
    <div>
      <Modal width='fit-content' border='none' modal={editModel} title='Edit Employee' close={close} content={<form onSubmit={handleFormSubmit}>
        <table>
          <tr>
            <td>
              <small className = "inputLabels">Full Name:-</small>
              <input className='text' type="text" placeholder="Enter Name" value={editData.fullName} onChange={(e) => setEditData({ ...editData, fullName: e.target.value })} id="fullName"></input>
            </td>
            <td>
              <small className = "inputLabels">Employee Id:-</small>
              <input className='text' type="text" placeholder="Enter Id" value={editData.employeeId} onChange={(e) => setEditData({ ...editData, employeeId: e.target.value })} id="employeeId"></input>
              </td>
            <td>
              <small className = "inputLabels">Gender</small>
              <select className="from_dropdown text" value={editData.genderId} onChange={(e) => setEditData({ ...editData, genderId: e.target.value })} id="genderId">
                  <option value="">Select Gender</option>
                  {genderList(gender)}
              </select>
            </td>
            <td>
              <small className = "inputLabels">Email:-</small>
              <input className='text' type="text" placeholder="Enter Email" value={editData.email} onChange={(e) => setEditData({ ...editData, email: e.target.value })} id="email"></input>
            </td>
          </tr>
          <tr>
            <td>
              <small className = "inputLabels">Phone:-</small>
              <input className='text' type="text" placeholder="Enter Phone" value={editData.phone} onChange={(e) => setEditData({ ...editData, phone: e.target.value })} id="phone"></input>          
            </td>
            <td>
              <small className = "inputLabels">Address:-</small>
              <input className='text' type="text" placeholder="Enter Address" value={editData.address} onChange={(e) => setEditData({ ...editData, address: e.target.value })} id="address"></input>
            </td>
            <td>
              <small className = "inputLabels">About</small>
              <input className='text' type="text" placeholder="Enter About" value={editData.about} onChange={(e) => setEditData({ ...editData, about: e.target.value })} id="about"></input>
            </td>
            <td>
              <small className = "inputLabels">Picture</small>
              <label htmlFor='files' className='file'>
                <input className='text' type='file' id='files' name='files' multiple onChange={handleFileChange} accept="image/png, image/jpg, image/jpeg" />
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <small className = "inputLabels">Experience</small>
              <input className='text' type="text" placeholder="Enter Experience" value={editData.experience} onChange={(e) => setEditData({ ...editData, experience: e.target.value })} id="experience"></input>
            </td>
            <td>
              <small className = "inputLabels">Designation</small>
              <select className="from_dropdown text" value={editData.designationId} onChange={(e) => setEditData({ ...editData, designationId: e.target.value })} id="designationId">
                  <option value="">Select Designation</option>
                  {designationList(designation)}
              </select>
            </td>
            <td>
              <small className = "inputLabels">Department</small>
              <select className="from_dropdown text" value={editData.departmentId} onChange={(e) => setEditData({ ...editData, departmentId: e.target.value })} id="departmentId">
                  <option value="">Select Department</option>
                  {departmentList(department)}
              </select>
            </td>
            <td>
              <small className = "inputLabels">Committee</small>
              <select className="from_dropdown text" value={editData.committeeId} onChange={(e) => setEditData({ ...editData, committeeId: e.target.value })} id="committeeId">
                  <option value="">Select Committee</option>
                  {committeeList(committee)}
              </select>
            </td>
          </tr>
          <tr>
            <td>
                <small className = "inputLabels">Employee Type</small>
                <select className="from_dropdown text" value={editData.empTypeId} onChange={(e) => setEditData({ ...editData, empTypeId: e.target.value })} id="empTypeId">
                    <option value="">Select Employee Type</option>
                    {empTypeList(empList)}
                </select>
              </td>
          </tr>
        </table>
        { isLoading ? <CallModelSpinner/> : '' }
      </form>} actions={<button className='btn-gbl-green btn' type='submit' onClick={handleFormSubmit}>Submit</button>} />

      {/* Main form entry */}
      <div className="staff">
        <h2 className='title'>Employee Entry</h2>
        <table className='staffFormTable' id='tableData'>
          <tr>
            <td>
              <small className = "inputLabels">Full Name:-</small>
              <input className='text' type="text" placeholder="Enter Name" value={formData.fullName} onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} id="fullName"></input>
            </td>
            <td>
              <small className = "inputLabels">Employee Id:-</small>
              <input className='text' type="text" placeholder="Enter Id" value={formData.employeeId} onChange={(e) => setFormData({ ...formData, employeeId: e.target.value })} id="employeeId"></input>
              </td>
            <td>
              <small className = "inputLabels">Gender</small>
              <select className="from_dropdown text" value={formData.genderId} onChange={(e) => setFormData({ ...formData, genderId: e.target.value })} id="genderId">
                <option value="">Select Gender</option>
                {genderList(gender)}
              </select>
            </td>
            <td>
              <small className = "inputLabels">Email:-</small>
              <input className='text' type="text" placeholder="Enter Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} id="email"></input>
            </td>
          </tr>
          <tr>
            <td>
              <small className = "inputLabels">Phone:-</small>
              <input className='text' type="text" placeholder="Enter Phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} id="phone"></input>          
            </td>
            <td>
              <small className = "inputLabels">Address:-</small>
              <input className='text' type="text" placeholder="Enter Address" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} id="address"></input>
            </td>
            <td>
              <small className = "inputLabels">About</small>
              <input className='text' type="text" placeholder="Enter About" value={formData.about} onChange={(e) => setFormData({ ...formData, about: e.target.value })} id="about"></input>
            </td>
            <td>
              <small className = "inputLabels">Picture</small>
              <label htmlFor='files' className='file'>
                <input className='text' type='file' id='files' name='files' multiple onChange={handleFileChange} accept="image/png, image/jpg, image/jpeg" />
              </label>
              {/* <input className='image_file text' type="file" placeholder="Enter Picture" value={formData.profilePic} onChange={(e) => setFormData({ ...formData, profilePic: e.target.value })} id="profilePic" accept="image/png, image/jpg, image/jpeg"></input>           */}
            </td>
          </tr>
          <tr>
            <td>
              <small className = "inputLabels">Experience</small>
              <input className='text' type="text" placeholder="Enter Experience" value={formData.experience} onChange={(e) => setFormData({ ...formData, experience: e.target.value })} id="experience"></input>
            </td>
            <td>
              <small className = "inputLabels">Designation</small>
              <select className="from_dropdown text" value={formData.designationId} onChange={(e) => setFormData({ ...formData, designationId: e.target.value })} id="designationId">
                  <option value="">Select Designation</option>
                  {designationList(designation)}
              </select>
            </td>
            <td>
              <small className = "inputLabels">Department</small>
              <select className="from_dropdown text" value={formData.departmentId} onChange={(e) => setFormData({ ...formData, departmentId: e.target.value })} id="departmentId">
                  <option value="">Select Department</option>
                  {departmentList(department)}
              </select>
            </td>
            <td>
              <small className = "inputLabels">Committee</small>
              <select className="from_dropdown text" value={formData.committeeId} onChange={(e) => setFormData({ ...formData, committeeId: e.target.value })} id="committeeId">
                  <option value="">Select Committee</option>
                  {committeeList(committee)}
              </select>
            </td>
          </tr>
          <tr>
          <td>
              <small className = "inputLabels">Employee Type</small>
              <select className="from_dropdown text" value={formData.empTypeId} onChange={(e) => setFormData({ ...formData, empTypeId: e.target.value })} id="empTypeId">
                  <option value="">Select Employee Type</option>
                  {empTypeList(empList)}
              </select>
            </td>
            <td></td>
            <td></td>
            <td>
              <td className='btnTblRow'><button onClick={ handleFormSubmit } className="btn-gbl-green btn staffEntryBtn" type="button">Make Entry</button></td>
            </td>
          </tr>
        </table>
      </div>
      <hr/>
      <div className='excelData'>
        <div className='excelUpload'>
          <label htmlFor='excelfiles' className='file'>
            <input className='text' type='file' onChange={excelFileChange} ></input>
          </label>  
            <button onClick={ handleXlsxSubmit } className="btn-gbl-green btn staffExcelEntryBtn" type="button">Upload Excel</button>
        </div>      
      </div>  
      <hr/>
      <div>
      <div className='search'>
        <input type="text" className='searchBar' id='searchValue' placeholder='Search'  onChange={filterData}></input>
      </div>
      <div className='tblWrapper wrapperHeightStaff'>
        <table className='dataTable'>
          <thead>
            <tr>
              <th>id</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>About</th>
              <th>Profile Pic</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Committee</th>
              <th>Experience</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableFilter && tableFilter.map((data, index)=>{
              return(
                <tr key={data.id}>
                  <td>{index + 1}</td>
                  <td>{data.fullName}</td>
                  <td>{data.email}</td>
                  <td>{data.phone}</td>
                  <td>{data.address}</td>
                  <td>{data.about}</td>
                  <td><img className="tableImage" src={data.profilePic !== 'N/A' || '' ?  `${Image_URL}/${data.profilePic}` : `${Image_URL}/${'noPic.png'}`} alt="No File"/></td>
                  <td>{data.designation}</td>
                  <td>{data.department}</td>
                  <td>{data.committee}</td>
                  <td>{data.experience}</td>
                  <td>
                    <div className='btnFlex'>
                      <button onClick={() =>editFileChange(data.id) } className="btn-gbl-blue btn" type="button">Edit</button>                         
                      <button variant='contained' onClick={()=>deleteFileChange(data.id)} className="btn-gbl-red btn" type="button">Delete</button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>     
      </div>                 
      </div>
      { isLoading? <CallSpinner/>:''}
    </div>
  )
}

export default Staff;



