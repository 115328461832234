const resizeImage = (maxWidth, maxHeight, imageFile,imageType) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(imageFile);
        reader.onload = e => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                // TODO: write better logic based on aspect ratio of image, for now it works.
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const dUri = ctx.canvas.toDataURL(imageType, 1)
                if (!dUri)
                    reject("failed to generate dataURL!")
                // convert blob to file 
                ctx.canvas.toBlob(b => {
                    const newFile = new File([b], imageFile.name, { type: imageType, lastModified: Date.now() })
                    const obj = { uri: dUri, file: newFile }
                    if (!newFile)
                        reject("file was null!");
                    resolve(obj);
                });
            }
        }
    })
}

export default resizeImage;