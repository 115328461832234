import React from "react";
import  { MdClose } from "react-icons/md";
import '../css/modal.scss'

const Modal = ({ modal,border, width, title, close, content, actions }) => {
  if(modal) return (
    <div className='modal' style={{border: border ? border: ''}}>
      <div className='modal__container'>
        <div className='modal__box' style={{ maxWidth: width ? width : '' }}>
          <div className='modal__title'>
            <div className='title'>{title}</div>
            <button onClick={close} className='icon'><MdClose /></button>
          </div>
          <div className='modal__content'>{content}</div>
          {actions && <div className='modal__actions'>{actions}</div>}
        </div>
      </div>
    </div>
  );
  return null;
}

export default Modal;