import React from 'react'


const Footer = () => {
  return (
    <footer className='footer'>
      <p>Copyright@ {new Date().getFullYear()} Thakur Ram Multiple Campus. Powered by NexaVerse, Birgunj.</p>
    </footer>
  )
}

export default Footer;
