import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { base_URL } from "../middleware/config";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Modal from "../components/Modal";
import CallModelSpinner from "../components/callModelSpinner";
import ReactHTMLParser from "html-react-parser";
import DOMPurify from "dompurify";
import "../css/QAA.css";

const QAA = () => {
  const auth = localStorage.getItem("key");
  const [formData, setFormData] = useState({
    qaaPassword: '',
    token: JSON.parse(auth).token
  });
  const [qaaData, setQAAData] = useState([]);

  const [passwordData, setPasswordData] = useState()
  const [qaaDocList, setQaaDocList] = useState([])

  const [saveForm, setSaveForm] = useState({
    doccName: "",
    doccLink: "",
    token: JSON.parse(auth).token
  });

  const [editData, setEditData] = useState([]);
  const [token, setToken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [files, setFiles] = useState([]);
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [file3, setFile3] = useState([]);
  const [file4, setFile4] = useState([]);

  const [password, setPassword] = useState()
  const [fileName, setFileName] = useState()
  const [fileLink, setFileLink] = useState()
  const [editFileName, updateFileName] = useState()
  const [editFileLink, updateFileLink] = useState()

  // ck-editor data
  const getQAA = async () => {
    await axios.get(`${base_URL}/qaa/`).then((getData) => {
      setQAAData(getData.data.body);
    });
  };

  const saveQaaDoccs = async () => {
    // const finalData = { ...saveForm, doccName: fileName, doccLink: fileLink }
    const res = await axios.post(`${base_URL}/qaa/saveQaaDocc`, saveForm)
    const data = await res.data
    if (res.status === 200) {
      alert(data.message)
    } else {
      alert(data.message)
    }
  }

  let navigate = useNavigate();

  const close = () => {
    setEditModel(false);
  };

  useEffect(() => {
    getQAA();
    setToken(JSON.parse(auth).token);
    if (!auth) navigate("/");
  }, []);

  const getQaaDocc = async () => {
    const res = await axios.get(`${base_URL}/qaa/getQaaDocc`)
    const data = await res.data
    if (res.status === 200) {
      setQaaDocList(data.body)
    } else {
      //
    }
  }

  useEffect(() => {
    getQaaDocc()
  }, [])


  const generatePassword = async () => {
    // const formData = new FormData()
    // formData.append("qaaPassword", password);
    // formData.append("token" , token)
    // let finalData = {...formData, qaaPassword: password } 
    const response = await axios.post(`${base_URL}/user/generateQaaPassword`, formData);
    const data = await response.data
    if (response.status === 200) {
      alert(`${response.data.message}`);
      // toast.success(`${response.data.message}`,{
      //     theme: "colored",
      //     autoClose: 10000,
      //     position: "top-left"
      // });

      // setIsLoading(false);
      // navigate('/');
    }
    else {
      setIsLoading(false);
      alert(`${response.data.message}`);
      // toast.error(`${response.data.message}`);
      return;
    }
  }


  //function to edit
  const editFileChange = async () => {
    setIsLoading(true);
    let data = qaaData[0];
    setEditData(data);
    setEditModel(true);
    setIsLoading(false);
  };
  // handle file inputs
  // const handleFileChange = e => {
  //   if(e.target.files.length > 0) {
  //     let files = e.target.files;
  //     let x = []; //All files 
  //     let y = []; //Orignal name
  //     for(let i = 0 ; i < files.length; i++) {
  //       y.push(files[i].name);
  //       x.push(files[i]);
  //     }
  //     //setOrignalFileName(y);
  //     setFiles(x);
  //   }
  // }
  const handleFile1Change = (e) => {
    setFile1(e.target.files[0]);
  }

  const handleFile2Change = (e) => {
    setFile2(e.target.files[0]);
  }

  const handleFile3Change = (e) => {
    setFile3(e.target.files[0]);
  }

  const handleFile4Change = (e) => {
    setFile4(e.target.files[0]);
  }

  const handleEditFileName = (e) => {

    updateFileName(e.target.value)
  }
  const handleEditFileLink = (e) => {
    console.log("link updated")
    updateFileLink(e.target.value)
  }

  const handleFormSubmit = async () => {
    setIsLoading(true);
    const x = new FormData();
    for (let i = 0; i < files.length; i++) {
      x.append('files', files[i]);
    }
    let chkAuth = await axios.post(`${base_URL}/upload/chkAuth`, { token });
    if (chkAuth.data.status === 200) {
      await axios.post(`${base_URL}/upload`, x).then(async res => {
        let finalData = { ...editData, QAAFiles: res.data.body.files1, token: token };
        let response = await axios.put(`${base_URL}/qaa`, finalData);
        if (response.data.status === 200) {
          alert(`${response.data.message}`);
          navigate('/qaa');
          setIsLoading(false);
          window.location.reload(false);
          //   setTimeout(() => {
          //       setIsLoading(false);
          //     }, 3000);
        }
        else {
          alert(`${response.data.message}`);
          setIsLoading(false);
          return;
        };
      });
    }
  }
  // const handleFormSubmit = async () => {
  //     setIsLoading(true);
  //     let finalData = { ...editData, token: token };
  //     let response = await axios.put(`${base_URL}/qaa/`, finalData);
  //     if (response.data.status === 200) {
  //       alert(`${response.data.message}`);
  //       navigate("/qaa");
  //       setIsLoading(false);
  //       window.location.reload(false);
  //     } else {
  //       alert(`${response.data.message}`);
  //       setIsLoading(false);
  //       return;
  //     }
  //   };

  const fileRef = useRef(null)
  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  // const handleFileName = (e) => {
  //   setSaveForm({ ...saveForm, doccName: e.target.value })
  //   setFileName(e.target.value)
  // }

  // const handleFileLink = (e) => {
  //   setSaveForm({ ...saveForm, doccLink: e.target.value })
  //   setFileLink(e.target.value)
  // }

  return (
    <div className="qaa">
      <h2 className="title">Contact Entry</h2>
      <table className="contactForm" id="contactData">
        <tr>
          <td>
            <small>In case to change contact information. Press Edit.</small>
          </td>
        </tr>
      </table>
      <section className="passwordUpdater" style={{ margin: "2rem 0", border: "1px solid blue", padding: "25px 0" }}>
        <div style={{ display: "inline-block" }}>
          <label style={{ fontSize: "x-small", fontWeight: "bold", margin: "0 2rem 0 0" }} htmlFor="password">&nbsp;&nbsp;Password&nbsp;&nbsp;
            <input className="qaa-input-field" type="text" name="password" id="password" onChange={(e) => setFormData({ ...formData, qaaPassword: e.target.value })} />
          </label>
          <button className="qaa-btn" onClick={() => generatePassword()} >Update</button>
        </div>

      </section>

      <section className="ssr" style={{ margin: "2rem 0", border: "1px solid blue", padding: "25px 0" }}>
        <div style={{ display: "inline-block" }}>
          <label style={{ fontSize: "x-small", fontWeight: "bold", margin: "0 2rem 0 0" }} htmlFor="doccName">&nbsp;&nbsp;FileName&nbsp;&nbsp;
            <input className='qaa-input-field' type="text" placeholder="Enter File Name" value={saveForm.doccName} onChange={(e) => setSaveForm({ ...saveForm, doccName: e.target.value })} id="doccName"></input>
            {/* <input type="text" id="doccName" name="doccName" onChange={(e) => handleFileName} /> */}
          </label>
          <label style={{ fontSize: "x-small", fontWeight: "bold", margin: "0 2rem 0 0" }} htmlFor="doccLink">&nbsp;&nbsp;FileLink&nbsp;&nbsp;
            <input className='qaa-input-field' type="text" placeholder="Enter File Link" value={saveForm.doccLink} onChange={(e) => setSaveForm({ ...saveForm, doccLink: e.target.value })} id="doccLink"></input>
            {/* <input type="text" id="doccLink" name="doccLink" onChange={(e) => handleFileLink}/> */}
          </label>
          <button className="qaa-btn set-btn" onClick={() => {
            saveQaaDoccs()
          }} >Set</button>
        </div>
      </section>

      {qaaDocList &&
        (<section ref={fileRef} className="filelist" style={{ display: "flex", justifyContent: "center", overflow: 'auto' }}>
          <table style={{ borderCollapse: "separate", border: "1px solid black", borderSpacing: "16px" }}>
            <thead>
              <tr>
                <th>File </th>
                <th>Link</th>
                <th colSpan={2}>Edit</th>
              </tr>
            </thead>
            <tbody >
              {
                qaaDocList.map((data, index) => {

                  return (<>
                    <tr className={`update-row-${index}`} key={index}>
                      <td><input className="qaa-input-field" type="text" defaultValue={data.doccName} name="fileName" onChange={(e) => handleEditFileName(e)} /></td>
                      <td><input defaultValue={data.doccLink} type="text" className="qaa-input-field" name="fileLink" onChange={(e) => handleEditFileLink(e)} /></td>
                      <td>
                        <button className="qaa-btn update-btn-color update-hover-color update-active-color" onClick={async (e) => {
                          e.preventDefault();
                          const fName = document.querySelector(`.update-row-${index}`).childNodes[0].childNodes[0].value
                          const dName = document.querySelector(`.update-row-${index}`).childNodes[1].childNodes[0].value
                          const formXData = { id: data.id, doccName: fName, doccLink: dName, token: JSON.parse(auth).token }
                          const res = await axios.put(`${base_URL}/qaa/updateQaaDocc`, formXData)
                          const dat = await res.data
                          if (res.status === 200) {
                            //console.log(dat)
                          } else {
                            //
                            console.log("error")
                          }

                        }}>Update</button>
                      </td>
                      <td>
                        <button className="qaa-btn" onClick={async (e) => {
                          // delete doc
                          const resp = await axios.put(`${base_URL}/qaa/deleteQaaDocc?id=${data.id}`, { token })
                          const dat = resp.data

                          if (resp.status === 200) {
                            console.log(dat)
                          } else {
                            console.log("error")
                          }
                        }}>Delete</button>
                      </td>
                    </tr>
                  </>)
                })
              }

            </tbody>
          </table>
        </section>)}

      <div className="tblWrapper wrapperHeightContact">
        <table className="dataTable">

          {/* <thead>
                <tr>
                <th> Doccument 1 </th>
                <th> Doccument 2 </th>
                <th> Doccument 3 </th>
                <th> Doccument 4 </th>
                </tr>
            </thead> */}
          {/* <tbody>
            {qaaData &&
              qaaData.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{data.document1} </td>
                    <td>{data.document2} </td>
                    <td>{data.document3} </td>
                    <td>{data.document4} </td>
                  </tr>
                );
              })}
            </tbody> */}
          <thead>
            <tr>
              <th> Establishment </th>
              <th> Objectives </th>
              <th> Committees Info </th>
              <th> Contact Info </th>
            </tr>
          </thead>
          <tbody>
            {qaaData &&
              qaaData.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td className="ckEdit">{ReactHTMLParser(DOMPurify.sanitize(data.establishment, {
                      USE_PROFILES: { html: true },
                    }))}</td>
                    <td className="ckEdit">{ReactHTMLParser(DOMPurify.sanitize(data.objectives, {
                      USE_PROFILES: { html: true },
                    }))}</td>
                    <td className="ckEdit">{ReactHTMLParser(DOMPurify.sanitize(data.committeesInfo, {
                      USE_PROFILES: { html: true },
                    }))}</td>
                    <td className="ckEdit">{ReactHTMLParser(DOMPurify.sanitize(data.contactInfo, {
                      USE_PROFILES: { html: true },
                    }))}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <table className="dataTable">
          <tr>
            <div className="btnFlex">
              <button
                variant="contained"
                onClick={() => editFileChange(1)}
                className="btn-gbl-blue btn contactEditBtn"
                type="button"
              >
                Edit QAA Info
              </button>
            </div>
          </tr>
        </table>
      </div>
      <Modal
        width="fit-content"
        border="none"
        modal={editModel}
        title="Edit QAA"
        close={close}
        content={
          <form onSubmit={handleFormSubmit}>
            <table>
              <tr>
                <td>
                  <small className="inputLabels">Doccumnet 1</small>
                  <label htmlFor='files' className='file'>
                    <input className='text' type='file' id='files' name='files' multiple onChange={handleFile1Change} accept="application/pdf, image/png, image/jpg, image/jpeg" />
                  </label>
                </td>
                <td>
                  <small className="inputLabels">Doccumnet 2</small>
                  <label htmlFor='files' className='file'>
                    <input className='text' type='file' id='files' name='files' multiple onChange={handleFile2Change} accept="application/pdf, image/png, image/jpg, image/jpeg" />
                  </label>
                </td>
                <td>
                  <small className="inputLabels">Doccumnet 3</small>
                  <label htmlFor='files' className='file'>
                    <input className='text' type='file' id='files' name='files' multiple onChange={handleFile3Change} accept="application/pdf, image/png, image/jpg, image/jpeg" />
                  </label>
                </td>
                <td>
                  <small className="inputLabels">Doccumnet 4</small>
                  <label htmlFor='files' className='file'>
                    <input className='text' type='file' id='files' name='files' multiple onChange={handleFile4Change} accept="application/pdf, image/png, image/jpg, image/jpeg" />
                  </label>
                </td>
              </tr>
            </table>
            <small className="inputLabels">Establishment</small>
            <div className="contact-ck--container">
              <CKEditor
                data={`${editData.establishment}`}
                editor={ClassicEditor}

                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditData({ ...editData, establishment: data })
                  // console.log({ event, editor, data });
                }}

              />
            </div>
            <small className="inputLabels">Objective</small>
            <div className="contact-ck--container">
              <CKEditor
                data={`${editData.objectives}`}
                editor={ClassicEditor}

                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditData({ ...editData, objectives: data })
                  // console.log({ event, editor, data });
                }}

              />
            </div>
            <small className="inputLabels">Committee Info</small>
            <div className="contact-ck--container">
              <CKEditor
                data={`${editData.committeesInfo}`}
                editor={ClassicEditor}

                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditData({ ...editData, committeesInfo: data })
                  // console.log({ event, editor, data });
                }}

              />
            </div>
            <small className="inputLabels">Contact Info</small>
            <div className="contact-ck--container">
              <CKEditor
                data={`${editData.contactInfo}`}
                editor={ClassicEditor}

                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditData({ ...editData, contactInfo: data })
                  // console.log({ event, editor, data });
                }}

              />
            </div>
            {isLoading ? <CallModelSpinner /> : ""}
          </form>
        }
        actions={
          <button className="btn-gbl-green btn contact-form-btn" type="submit" onClick={handleFormSubmit}> Submit </button>
        }
      />
    </div>
  )
}

export default QAA